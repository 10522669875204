import {useCallback, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment-timezone'
import {useNavigate, useSearchParams} from 'react-router-dom'

import {useAuth} from '../../modules/auth'
import {useFetchEvents} from '../../queries/events'
import EventsForm from './components/EventsForm'
import EventDetailModal from './components/EventDetailModal'
import GenericModal from '../../modules/common/Modal'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import './events.scss'

const EventsIndex = () => {
  const [showCreateEventModal, setShowCreateEventModal] = useState(false)
  const [showEventDetailModal, setShowEventDetailModal] = useState(false)
  const [events, setEvents] = useState<any>([])
  const [selectedOption, setSelectedOption] = useState<'' | 'following' | 'all'>('')
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [calendarView, setCalendarView] = useState('month')
  const [searchParams] = useSearchParams()
  const startDate = searchParams.get('startDate') || moment().startOf('month').format('YYYY-MM-DD')
  const endDate = searchParams.get('endDate') || moment().endOf('month').format('YYYY-MM-DD')
  const navigate = useNavigate()
  const localizer = momentLocalizer(moment)
  const {getUserTimezone, selectedCommunity} = useAuth()
  const eventsResult = useFetchEvents(String(startDate), String(endDate))

  const handleEventDetailModalClose = () => {
    setShowEventDetailModal(false)
    setSelectedEvent(null)
  }

  const handleSelectEvent = async (event: any) => {
    setSelectedEvent(event)
    if (event?.stageId) {
      navigate(`/pipeline?pipelineId=${event?.pipelineStage?.pipeline?.uuid}`)
    } else {
      setShowEventDetailModal(true)
    }
  }

  const onNavigate = (newDate: any) => {
    const dateMoment = moment(newDate)
    const startDate = dateMoment.clone().startOf('month').format('YYYY-MM-DD')
    const endDate = dateMoment.clone().endOf('month').format('YYYY-MM-DD')
    navigate(`?startDate=${startDate}&endDate=${endDate}`)
  }

  useEffect(() => {
    eventsResult.refetch()
  }, [startDate])

  useEffect(() => {
    const formattedEvents = eventsResult.data?.events.map((event: any) => {
      const startDate = moment.utc(event.startDate).tz(getUserTimezone()).toDate()
      const endDate = moment.utc(event.endDate).tz(getUserTimezone()).toDate()
      return {
        ...event,
        startDate,
        endDate,
        title:
          calendarView === 'month'
            ? `${moment(startDate).format('hh:mm a')} - ${event.title}`
            : event.title,
        originalTitle: event.title,
      }
    })
    setEvents(formattedEvents)
  }, [eventsResult.data?.events, calendarView])

  const eventPropGetter = useCallback((event: any) => {
    return {
      ...(event.type === 'contract' && {
        className: 'rbc-event-contract',
      }),
    }
  }, [])

  return (
    <div className='card p-5 container calendar-container'>
      {selectedCommunity?.isCommunityAdmin && (
        <div className='d-flex justify-content-between align-items-center my-5'>
          <h2>
            Calendar <span className='fs-6'>({getUserTimezone()} Time)</span>
          </h2>
          <Button variant='primary' onClick={() => setShowCreateEventModal(true)}>
            <i className='fa-solid fa-add add-pipeline-icon m-0'></i>
            Create Event
          </Button>
        </div>
      )}

      <div>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor='startDate'
          endAccessor='endDate'
          onSelectEvent={handleSelectEvent}
          views={['month', 'week', 'day']}
          onNavigate={onNavigate}
          popup
          style={{height: '80vh'}}
          onView={(view) => setCalendarView(view)}
          eventPropGetter={eventPropGetter}
        />
      </div>

      {showCreateEventModal && (
        <GenericModal
          title={`${selectedEvent ? 'Edit Event' : 'Create Event'}`}
          showModal={showCreateEventModal}
          onHideModal={() => {
            setShowCreateEventModal(false)
            setSelectedEvent(null)
          }}
          hideFooter={true}
          modalProps={{
            size: 'lg',
            centered: true,
          }}
        >
          <EventsForm
            actionType={selectedOption}
            selectedEvent={selectedEvent}
            onSubmitEventForm={() => {
              setShowCreateEventModal(false)
              setSelectedEvent(null)
            }}
          />
        </GenericModal>
      )}

      {selectedEvent && (
        <EventDetailModal
          showEventDetailModal={showEventDetailModal}
          handleEventDetailModalClose={handleEventDetailModalClose}
          selectedEvent={selectedEvent}
          selectedCommunity={selectedCommunity}
          showEditEventModal={() => setShowCreateEventModal(true)}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setShowEventDetailModal={setShowEventDetailModal}
        />
      )}
    </div>
  )
}

export default EventsIndex
