import React, {useEffect} from 'react'

import FetchingSpinner from '../../../modules/common/FetchingSpinner'
import {useAuth} from '../../../modules/auth'
import WelcomeScreen from './WelcomeScreen'
import MessagesHeader from './MessagesHeader'
import MessageRow from './MessageRow'

interface UserMessagesProps {
  recipientUser: any
  userMessages: any
  isFetching: boolean
  isRefetching: boolean
  pendingMessage: any
  messageContainer: any
}

const UserMessages: React.FC<UserMessagesProps> = ({
  recipientUser,
  isFetching,
  isRefetching,
  userMessages,
  pendingMessage,
  messageContainer,
}) => {
  const {currentUser} = useAuth()

  useEffect(() => {
    // Scroll to the bottom when messages are loaded or new message is sent
    if (messageContainer.current) {
      messageContainer.current.scrollTop = messageContainer.current.scrollHeight
    }
  }, [userMessages])

  return (
    <div className='card card-2 rounded px-5 w-100'>
      {!isFetching && <MessagesHeader recipientUser={recipientUser} />}
      {!isFetching && userMessages?.length === 0 && (
        <WelcomeScreen currentUser={currentUser} recipientUser={recipientUser} />
      )}

      {isFetching && userMessages?.length === 0 && !isRefetching && <FetchingSpinner />}
      {userMessages?.length > 0 && (
        <div
          ref={messageContainer}
          className='chat-container mt-3 position-relative overflow-y-scroll pb-7'
        >
          {userMessages?.map((messageData: any) => (
            <MessageRow
              key={messageData.id}
              messageData={messageData}
              isRecipientMessage={messageData.userId !== currentUser?.id}
            />
          ))}
          {pendingMessage && (
            <>
              <MessageRow messageData={pendingMessage} isRecipientMessage={false} />
              <i className='ml10' style={{fontSize: '11px'}}>
                Sending...
              </i>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default UserMessages
