import moment from 'moment-timezone'

export const states = [
  {value: 'AL', name: 'Albama'},
  {value: 'AK', name: 'Alaska'},
  {value: 'AZ', name: 'Arizona'},
  {value: 'AR', name: 'Arkansas'},
  {value: 'CA', name: 'California'},
  {value: 'CO', name: 'Colorado'},
  {value: 'CT', name: 'Connecticut'},
  {value: 'DE', name: 'Delaware'},
  {value: 'FL', name: 'Florida'},
  {value: 'GA', name: 'Georgia'},
  {value: 'HI', name: 'Hawaii'},
  {value: 'ID', name: 'Idaho'},
  {value: 'IL', name: 'Illinois'},
  {value: 'IN', name: 'Indiana'},
  {value: 'IA', name: 'Iowa'},
  {value: 'KS', name: 'Kansas'},
  {value: 'KY', name: 'Kentucky'},
  {value: 'LA', name: 'Louisiana'},
  {value: 'ME', name: 'Maine'},
  {value: 'MD', name: 'Maryland'},
  {value: 'MA', name: 'Massachusetts'},
  {value: 'MI', name: 'Michigan'},
  {value: 'MN', name: 'Minnesota'},
  {value: 'MS', name: 'Mississippi'},
  {value: 'MO', name: 'Missouri'},
  {value: 'MT', name: 'Montana'},
  {value: 'NE', name: 'Nebraska'},
  {value: 'NV', name: 'Nevada'},
  {value: 'NH', name: 'New Hampshire'},
  {value: 'NJ', name: 'New Jersey'},
  {value: 'NM', name: 'New Mexico'},
  {value: 'NY', name: 'New York'},
  {value: 'NC', name: 'North Carolina'},
  {value: 'ND', name: 'North Dakota'},
  {value: 'OH', name: 'Ohio'},
  {value: 'OK', name: 'Oklahoma'},
  {value: 'OR', name: 'Oregon'},
  {value: 'PA', name: 'Pennsylvania'},
  {value: 'RI', name: 'Rhode Island'},
  {value: 'SC', name: 'South Carolina'},
  {value: 'SD', name: 'South Dakota'},
  {value: 'TN', name: 'Tennessee'},
  {value: 'TX', name: 'Texas'},
  {value: 'UT', name: 'Utah'},
  {value: 'VT', name: 'Vermont'},
  {value: 'VA', name: 'Virginia'},
  {value: 'WA', name: 'Washington'},
  {value: 'WV', name: 'West Virginia'},
  {value: 'WI', name: 'Wisconsin'},
  {value: 'WY', name: 'Wyoming'},
]

export const cities = [
  {name: 'New York', value: 'New York'},
  {name: 'Los Angeles', value: 'Los Angeles'},
  {name: 'Chicago', value: 'Chicago'},
  {name: 'Houston', value: 'Houston'},
  {name: 'Phoenix', value: 'Phoenix'},
  {name: 'Philadelphia', value: 'Philadelphia'},
  {name: 'San Antonio', value: 'San Antonio'},
  {name: 'San Diego', value: 'San Diego'},
  {name: 'Dallas', value: 'Dallas'},
  {name: 'San Jose', value: 'San Jose'},
  {name: 'Austin', value: 'Austin'},
  {name: 'Jacksonville', value: 'Jacksonville'},
  {name: 'Fort Worth', value: 'Fort Worth'},
  {name: 'Columbus', value: 'Columbus'},
  {name: 'Charlotte', value: 'Charlotte'},
  {name: 'San Francisco', value: 'San Francisco'},
  {name: 'Indianapolis', value: 'Indianapolis'},
  {name: 'Seattle', value: 'Seattle'},
  {name: 'Denver', value: 'Denver'},
  {name: 'Washington', value: 'Washington'},
  {name: 'Boston', value: 'Boston'},
  {name: 'El Paso', value: 'El Paso'},
  {name: 'Nashville', value: 'Nashville'},
  {name: 'Portland', value: 'Portland'},
  {name: 'Las Vegas', value: 'Las Vegas'},
  {name: 'Detroit', value: 'Detroit'},
  {name: 'Oklahoma City', value: 'Oklahoma City'},
  {name: 'Memphis', value: 'Memphis'},
  {name: 'Louisville', value: 'Louisville'},
  {name: 'Baltimore', value: 'Baltimore'},
  {name: 'Milwaukee', value: 'Milwaukee'},
  {name: 'Albuquerque', value: 'Albuquerque'},
  {name: 'Tucson', value: 'Tucson'},
  {name: 'Fresno', value: 'Fresno'},
  {name: 'Sacramento', value: 'Sacramento'},
  {name: 'Mesa', value: 'Mesa'},
  {name: 'Kansas City', value: 'Kansas City'},
  {name: 'Atlanta', value: 'Atlanta'},
  {name: 'Long Beach', value: 'Long Beach'},
  {name: 'Omaha', value: 'Omaha'},
  {name: 'Raleigh', value: 'Raleigh'},
  {name: 'Colorado Springs', value: 'Colorado Springs'},
  {name: 'Miami', value: 'Miami'},
  {name: 'Virginia Beach', value: 'Virginia Beach'},
  {name: 'Oakland', value: 'Oakland'},
  {name: 'Minneapolis', value: 'Minneapolis'},
  {name: 'Tulsa', value: 'Tulsa'},
  {name: 'Arlington', value: 'Arlington'},
  {name: 'New Orleans', value: 'New Orleans'},
]

export const FormatedDateDifference = (unixTime: number) => {
  const createdTime = moment.unix(unixTime)

  return createdTime.fromNow()
}

export const FormatedDateDifferenceComment = (time: string) => {
  return moment(time).fromNow()
}

export const debounce = (func: any, delay: number) => {
  let debounceTimer: any
  return function (...args: any) {
    // @ts-ignore
    const context = this
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}

export const getSelectedCommunity = () => {
  try {
    return JSON.parse(localStorage.getItem('selectedCommunity') || '')
  } catch {
    return null
  }
}

export const getSelectedCommunityId = () => {
  try {
    const community = JSON.parse(localStorage.getItem('selectedCommunity') || '')
    return community?.uuid
  } catch {
    return null
  }
}

export const parseError = (error: any) => {
  return error?.response?.data?.message || 'An error occurred, please try again later.'
}

export const isProfileIncomplete = (user: any) => {
  return ['firstName', 'lastName', 'state', 'city', 'companyName', 'bio', 'profilePic'].some(
    (field) => !(user as any)[field]
  )
}

export const getTimezoneOptions = () => {
  return moment.tz.names().map((timezone) => {
    const offset = moment.tz(timezone).format('Z')
    return {value: timezone, label: `(GMT ${offset}) ${timezone}`}
  })
}

export const rewriteDateInOtherTimezone = (dateString: any, timezone: string) => {
  if(!dateString) return null;
  const originalDate = new Date(dateString)

  // Create a new moment object in the given timezone with the same time
  const newDateInTimezone = moment.tz(
    {
      year: originalDate.getFullYear(),
      month: originalDate.getMonth(),
      day: originalDate.getDate(),
      hour: originalDate.getHours(),
      minute: originalDate.getMinutes(),
      second: originalDate.getSeconds(),
    },
    timezone
  )

  return newDateInTimezone.format() // Returns in ISO format
}

export const createDateFromMoment = (momentObj: any) => {
  const year = momentObj.year()
  const month = momentObj.month()
  const date = momentObj.date()
  const hours = momentObj.hours()
  const minutes = momentObj.minutes()
  const seconds = momentObj.seconds()
  const milliseconds = momentObj.milliseconds()

  return new Date(year, month, date, hours, minutes, seconds, milliseconds)
}

export const getTimeUntilEvent = ({
  eventStartDate,
  timezone,
}: {
  eventStartDate: string
  timezone: string
}): string => {
  const eventStartMoment = moment.utc(eventStartDate).tz(timezone)
  const currentTimeMoment = moment.tz(timezone)

  const hoursUntilEvent = eventStartMoment.diff(currentTimeMoment, 'hours')
  const minutesUntilEvent = eventStartMoment.diff(currentTimeMoment, 'minutes')
  const secondsUntilEvent = eventStartMoment.diff(currentTimeMoment, 'seconds')

  if (hoursUntilEvent >= 1) {
    return `${hoursUntilEvent} hour(s)`
  } else if (minutesUntilEvent >= 1) {
    return `${minutesUntilEvent} minute(s)`
  } else if (secondsUntilEvent >= 1) {
    return `${secondsUntilEvent} second(s)`
  } else {
    return 'now'
  }
}

export const getContractTimeLeft = (dueDate: string, timezone: string): string => {
  const now = moment().tz(timezone)
  const due = moment.utc(dueDate).tz(timezone)
  const diffInSeconds = due.diff(now, 'seconds')

  if (diffInSeconds < 0) return `Due Date: ${String(due.format('DD MMMM YYYY'))}`
  if (diffInSeconds < 60) return `Due in ${diffInSeconds} seconds`
  if (diffInSeconds < 3600) return `Due in ${Math.floor(diffInSeconds / 60)} minutes`
  if (diffInSeconds < 86400) return `Due in ${Math.floor(diffInSeconds / 3600)} hours`
  return `Due in ${Math.floor(diffInSeconds / 86400)} days`
}
